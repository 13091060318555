import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import parse from 'html-react-parser'

const Card = loadable(() => import('/src/components/Card'))
const Header = loadable(() => import('/src/components/Header'))
const Footer = loadable(() => import('/src/components/Footer'))
const InnerSection = loadable(() => import('/src/components/InnerSection'))
const Section = loadable(() => import('/src/components/Section'))
const Seo = loadable(() => import('/src/components/Seo'))

const StandardPage = ({ postInfo, post }) => {
    return (
        <>
            <Seo postInfo={postInfo} post={post} />

            <Header />

            <main>
                <Section variant="alt" className="c-page-body">
                    <Card>
                        <InnerSection>
                            <h1>{post.title}</h1>

                            {parse(post.content)}
                        </InnerSection>
                    </Card>
                </Section>
            </main>

            <Footer />
        </>
    )
}

StandardPage.propTypes = {
    /**
     * Post info object
     */
    postInfo: PropTypes.object.isRequired,
    /**
     * Post object
     */
    post: PropTypes.object.isRequired
}

export default StandardPage
